import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import axios from "axios";
import backend_url from "../../../config";

const BlogDetails = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { slug } = useParams();
  const [blog, setBlog] = useState(null);

  useEffect(() => {
    console.log(slug);
    setIsLoading(true);
    axios
      .get(`${backend_url}/blog?id=${slug}`)
      .then((data) => {
        setBlog(data.data);
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [slug]);

  if (isLoading) {
    return (
      <div style={styles.centered}>
        <p style={styles.loadingText}>Loading...</p>
      </div>
    );
  }

  if (!blog) {
    return (
      <div style={styles.centered}>
        <h2 style={styles.errorText}>Blog not found!</h2>
      </div>
    );
  }

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>{blog.title}</h1>
      <p style={styles.date}>{blog.date}</p>
      <div style={styles.content}>
        <ReactMarkdown>{blog.content}</ReactMarkdown>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
    maxWidth: "800px",
    margin: "0 auto",
    color: "#000",
  },
  centered: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    textAlign: "center",
  },
  errorText: {
    fontSize: "24px",
    color: "#FF0000",
  },
  loadingText: {
    fontSize: "20px",
    color: "#555",
  },
  title: {
    fontSize: "50px",
    fontWeight: "bold",
    marginBottom: "10px",
    textAlign: "center",
    color: "#000",
  },
  date: {
    fontSize: "18px",
    color: "#777",
    marginBottom: "10px",
  },
  content: {
    lineHeight: "1.6",
    fontSize: "22px",
    textAlign: "justify",
    color: "#000",
    fontFamily: "Ubuntu",
  },
};

export default BlogDetails;
