import React from "react";
import "../blog/Blog.css";

import { Fade } from "react-awesome-reveal";

const Code = () => {
  return (
    <Fade big>
      <div
        className="services__box1 aboutdiv"
        style={{
          borderRadius: "20px",
          padding: "20px",
          height: "auto",
        }}
      >
        <p className="tl">
          <span className="mr1 notneon">▹ </span>
          <span className="mr2">
            <b>Programming Languages :</b>
          </span>
          <span className="">
            Python, JavaScript, TypeScript, Dart, HTML/CSS, SQL
          </span>
        </p>
        <p className="tl">
          <span className="mr1 notneon">▹ </span>
          <span className="mr2">
            <b>Libraries & Frameworks :</b>
          </span>
          <span className="">
            React, Next, Node, Express, FastAPI, AWS, Flutter, Redis, MongoDB,
            Tailwind, Firebase, Drizzle, Prisma
          </span>
        </p>
        <p className="tl">
          <span className="mr1 notneon">▹ </span>
          <span className="mr2">
            <b>Developer Tools :</b>
          </span>
          <span className="">
            Git, VS Code, Vite, Android Studio, XCode, Docker
          </span>
        </p>
      </div>
    </Fade>
  );
};

export default Code;
