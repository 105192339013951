import React, { useState } from "react";
import "./SidePanel.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVideo } from "@fortawesome/free-solid-svg-icons";
import YouTubePlayer from "./YouTubePlayer";

const SidePanel = ({ node, onClose }) => {
  const [activeTab, setActiveTab] = useState("video");

  /* eslint-disable-next-line no-unused-vars */
  const [_, setCurrentTime] = useState(0);

  if (!node) return null;
  const nodeData = node.data;

  return (
    <div className={`panel-container ${node ? "open" : ""}`}>
      <div className="tab-container">
        <button
          className={`tab-button ${activeTab === "video" ? "active" : ""}`}
          onClick={() => setActiveTab("video")}
        >
          <FontAwesomeIcon icon={faVideo} /> Video
        </button>
        <button className="close-button" onClick={onClose}>
          &times;
        </button>
      </div>
      <div style={{ display: activeTab === "video" ? "block" : "none" }}>
        <h2 className="header">{nodeData.label}</h2>
        <p className="length" style={{ marginBottom: "0px", marginTop: "0px" }}>
          Length: {nodeData.length}
        </p>
        <p className="description">{nodeData.description}</p>
        <ol className="panel-list-keypoints">
          {nodeData.keyPoints.map((point, index) => (
            <li key={index} className="list-item">
              {point}
            </li>
          ))}
        </ol>

        <YouTubePlayer
          videoId={nodeData.videoId}
          playlistId={nodeData.playlistId}
          setActiveTab={setActiveTab}
          setCurrentTime={setCurrentTime}
        />
      </div>
    </div>
  );
};

export default SidePanel;
