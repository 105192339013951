/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import "./Blog.css";
import "../../../index.css";
import "../../home.css";

import { Fade } from "react-awesome-reveal";
import { FaMediumM } from "react-icons/fa";
import axios from "axios";
import backend_url from "../../../config";
import { Link } from "react-router-dom";

export default function Blog() {
  const [arr, setArr] = useState([]);
  const url =
    "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@namanrivaan";

  useEffect(() => {
    getAllTitle();
  }, []);

  const getAllTitle = () => {
    axios
      .get(url)
      .then((res) => {
        const mediumBlogs = res.data.items;
        axios
          .get(`${backend_url}/blogs`)
          .then((res) => {
            const mdBlogs = res.data;
            const allBlogs = mdBlogs.concat(mediumBlogs);
            setArr(allBlogs);
          })
          .catch((err) => console.error(`Error: ${err}`));
      })
      .catch((err) => console.error(`Error: ${err}`));
  };

  if (arr.length > 0) {
    return (
      <Fade>
        <div className="services">
          <div className="container">
            <div className="row bgMain">
              {arr.map((item, idx) => {
                return (
                  <Link
                    className="col-4 bgMain"
                    to={"slug" in item ? `/blogs/${item.slug}` : item["link"]}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <a target="_blank" rel="noopener noreferrer">
                      <div className="services__box1">
                        <br></br>
                        <FaMediumM className="commonIcons" />
                        <div className="services__box-header">
                          {item["title"]}
                        </div>
                      </div>
                    </a>
                  </Link>
                );
              })}
            </div>
          </div>
        </div>
      </Fade>
    );
  } else {
    return <div></div>;
  }
}
